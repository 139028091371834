import { merge } from './merge';

/**
 * Add default properties to an object. Leftmost wins.
 * @param obj Specified properties (leftmost wins)
 * @param defaults The defaults which should be used if those properties are not
 * present on the options object.
 */
export function defaultsDeep<T extends object, U extends object>(
  obj: T | undefined,
  defaults: U
): T & U;
/**
 * Add default properties to an object. Leftmost wins.
 * @param obj Specified properties (leftmost wins)
 * @param defaultsA The defaults which should be used if prior properties are not
 * @param defaultsB The defaults which should be used if prior properties are not
 */
export function defaultsDeep<T extends object, U extends object, V extends Object>(
  obj: T | undefined,
  defaultsA: U,
  defaultsB: V
): T & U & V;
/**
 * Add default properties to an object. Leftmost wins.
 * @param obj Specified properties (leftmost wins)
 * @param defaultsA The defaults which should be used if prior properties are not
 * @param defaultsB The defaults which should be used if prior properties are not
 * @param defaultsC The defaults which should be used if prior properties are not
 */
export function defaultsDeep<
  T extends object,
  U extends object,
  V extends Object,
  W extends Object
>(obj: T | undefined, defaultsA: U, defaultsB: V, defaultsC: W): T & U & V & W;
/**
 * Add default properties to an object. Leftmost wins.
 * @param defaultObjects The given properties and defaults to merge. (Leftmost wins.)
 */
export function defaultsDeep<T extends object>(...defaultObjects: T[]): T;
export function defaultsDeep<T extends object>(...defaultObjects: T[]): T {
  if (defaultObjects.length === 1) return defaultObjects[0];
  const source = { ...defaultObjects[defaultObjects.length - 2] };
  const destination = { ...defaultObjects[defaultObjects.length - 1] };
  const mergedObj = merge(destination, source, true);
  return defaultsDeep(...defaultObjects.slice(0, -2), mergedObj);
}
