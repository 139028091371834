// Merge `destination` hash with `source` hash, overwriting like keys
// in `source` if necessary.

import { get } from './get';
import { isObject } from './isObject';

/**
 * Like Object.assign but with deep by default.
 */
export function merge<Destination extends object, Source extends object>(
  destination: Destination,
  source: Source,
  deep?: boolean
): Destination & Source;
/**
 * Like Object.assign but with deep by default.
 */
export function merge<Destination, Source>(
  destination: Destination[],
  source: Source[],
  deep?: boolean
): (Destination & Source)[];
/**
 * Like Object.assign but with deep by default.
 */
export function merge(
  destination: any[] | object,
  source: any[] | object,
  deep: boolean | undefined = true
) {
  // If the destination is not assignable (i.e., a primitive), then
  // we cannot merge onto it. Instead, we clobber it with source.
  if (!isObject(destination) && !Array.isArray(destination) && typeof destination !== 'function')
    return source;

  Object.keys(source).forEach((property) => {
    if (
      deep &&
      // The destination prop is an object or array:
      (isObject(get(destination, property)) || Array.isArray(get(destination, property))) &&
      // The source prop is an object or array:
      (isObject(get(source, property)) || Array.isArray(get(source, property)))
    ) {
      (destination as any)[property] = merge(
        (destination as any)[property],
        (source as any)[property],
        deep
      );
    } else if ((source as any)[property] !== undefined) {
      // If the source is defined, use that value, otherwise, do nothing.
      // eslint-disable-next-line no-param-reassign
      (destination as any)[property] = (source as any)[property];
    }
  });
  return destination;
}
